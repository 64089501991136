<template>
    <div class="custom">
        <!-- 客户信息 -->
        <div class="header">
            <img src="../../../assets/img/man.png" class="head-img"/>
            <div class="content">
                <div class="name">{{ customerUserInfo.nick_name }}-{{ customerUserInfo.mobile }}</div>
                <div class="info">
                    <div class="lable">
                        <span v-if="customerUserInfo.qywx_label"
                              class="tag-blue">{{ customerUserInfo.qywx_label }}</span>
                        <button class="btn-orange" @click="setupClick">设置</button>
                    </div>
                    <div v-if="customerUserInfo.grade" class="grade">会员等级：{{ customerUserInfo.grade.name }}</div>
                </div>
            </div>
        </div>
				<!-- 绑定客户 -->
				<div class="cell-list" v-if="!hasCustomerUserId">
					<div class="cell-list-item">
						<div class="name">
							<i class="el-icon-user"></i>绑定客户
						</div>
						<el-button type="text" @click="binding">绑定</el-button>
					</div>
				</div>
				<!-- 绑定客户弹窗 -->
				<van-popup v-model="bindingShow" position="bottom" round>
					<div class="bind-popup">
						<el-input v-model="mobile" placeholder="请输入手机号"/>
						<el-button type="primary" @click="bindUser">绑定客户</el-button>
					</div>
				</van-popup>
        <!-- 卡片 -->
        <div class="card" v-if="hasCustomerUserId">
            <div class="card-item" v-for="(item,index) in cardList" :key="index">
                <span>{{ item.num }}</span>
                <em>（{{ item.unit }}）</em>
                <p>{{ item.name }}</p>
            </div>
        </div>
        <!-- 设置弹窗 -->
        <popupInput ref="popupInput" placeholder="请设置备注" @defineClick="defineClick"></popupInput>
    </div>
</template>

<script>
import * as QywxApi from '@/api/qywx'
import popupInput from '../components/popupInput.vue'
import {
  checkRedirect,
  initSdk,
  invoke,
  asyncCall,
  call
} from 'wecom-sidebar-jssdk';
import config from "../config";

export default {
    components: {
        popupInput
    },
    data() {
        return {
					bindingShow:false,
            hasCustomerUserId: false,
            lable: '回头客',
            cardList: [{
                num: 0,
                unit: '笔',
                name: "累计购买订单数"
            }, {
                num: 0,
                unit: '笔',
                name: "已完成订单数"
            }, {
                num: 0,
                unit: '笔',
                name: "退款订单数"
            }, {
                num: 0,
                unit: '元',
                name: "累计销售金额"
            }, {
                num: 0,
                unit: '元',
                name: "已完成订单金额"
            }, {
                num: 0,
                unit: '元',
                name: "退款金额"
            }],
            mobile: '',
            customerUserInfo: {},
        }
    },
    created() {
        initSdk(config, this.fetchSignatures).then(() => this.getCustomerUserInfo())
    },
    methods: {
      async fetchSignatures() {
        let url = window.location.href
        let qywxUserId = localStorage.getItem('qywxUserId')
        if (url.split('?').length > 1 && qywxUserId) {
          url = url.split('?')[0]
        }
        let res = await QywxApi.getSignatures({
          url: url
        });
        let signatures = res.data.signatures
        return signatures
      },
      async getCustomerUserInfo() {
        try {
          const res = await invoke('getContext');
          console.log(res);

          const res1 = await invoke('getCurExternalContact')
          if (res1.err_msg == "getCurExternalContact:ok") {
            let customerId = res1.userId;
            localStorage.setItem('customerId', customerId)
            QywxApi.getUserInfoByQywxUserId({
              qywxUserId: customerId
            }).then(response => {
              let customerUser = response.data.userInfo
              if (customerUser && customerUser.user_id != null) {
                localStorage.setItem('customerUserId', customerUser.user_id)
                this.customerUserInfo = customerUser
                this.hasCustomerUserId = true
                this.getIndexCount()
              }
            })
          } else {
            this.$message({
              message: '获取客户信息失败！',
              type: 'error'
            });
          }
        } catch (e) {
          console.log(e.message);
        }
      },
			// 客户绑定
			binding(){
				this.bindingShow=true
			},
        // 设置弹窗确定
        defineClick(id, type, val) {
            let qywxLable = val
            let customerUserId = localStorage.getItem('customerUserId')
            QywxApi.setUserLabel({userId: customerUserId, label: qywxLable}).then(response => {
								this.$toast.success('设置成功');
                this.customerUserInfo = response.data.userInfo
                this.$forceUpdate()
            })
        },
        // 设置
        setupClick() {
            this.$refs.popupInput.show()
        },
        bindUser() {
            if (this.mobile) {
                let qywxUserId = localStorage.getItem('customerId')
                QywxApi.bindUser({mobile: this.mobile, qywxUserId: qywxUserId}).then(response => {
                    let customerUserId = response.data.userId
                    localStorage.setItem('customerUserId', customerUserId)
										this.$toast.success('绑定成功');
										this.bindingShow=false
                    this.hasCustomerUserId = true
                    this.getCustomerUserInfo()
                })
            } else {
							this.$toast.fail('请输入手机号');
            }
        },
        // getCustomerUserInfo() {
        //   let that = this
        //   let customerId = localStorage.getItem('customerId')
        //   QywxApi.getUserInfoByQywxUserId({
        //     qywxUserId: customerId
        //   }).then(response => {
        //     let customerUser = response.data.userInfo
        //     if (customerUser && customerUser.user_id != null) {
        //       localStorage.setItem('customerUserId', customerUser.user_id)
        //       that.customerUserInfo = customerUser
        //       that.hasCustomerUserId = true
        //       that.getIndexCount()
        //     }
        //   })
        //   // // let customerUserId = localStorage.getItem('customerUserId')
        //   // let customerUserId = customerUser.userId
        //   // if (customerUserId) {
        //   //   QywxApi.getUserInfo({userId: customerUserId}).then(response => {
        //   //     this.customerUserInfo = response.data.userInfo
        //   //   })
        //   // }
        // },
        getIndexCount() {
            let customerUserId = localStorage.getItem('customerUserId')
            if (customerUserId) {
                QywxApi.getUserIndexCount({userId: customerUserId}).then(response => {
                    let counts = response.data.count
                    this.cardList[0].num = counts.orderCount
                    this.cardList[1].num = counts.orderCompletedCount
                    this.cardList[2].num = counts.orderRefundCount
                    this.cardList[3].num = counts.orderPriceCount
                    this.cardList[4].num = counts.orderCompletedPriceCount
                    this.cardList[5].num = counts.orderRefundPriceCount
                })
            }
        }
    },

}
</script>

<style lang="scss" scoped>
@import "@/assets/css/wecom.scss";
</style>
